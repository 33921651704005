import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './components/pages/home/Home';
import AboutUs from './components/pages/aboutUs/AboutUs';
import Credits from './components/pages/credits/Credits';
import CreditoRevolvente from './components/pages/credito-revolvente/Credits';
import CreditoPuente from './components/pages/credito-puente/Credits';
import CreditoLiquidez from './components/pages/credito-liquidez/Credits';
import ContactUs from './components/pages/contactUs/ContactUs';
import PrivacyNotice from './components/pages/privacyNotice/PrivacyNotice';
import FinancialEntitiesBuroInfo from './components/pages/financialEntitiesBuroInfo/FinancialEntitiesBuroInfo';
import NotFound from './components/pages/notFound/NotFound';

const Main = () => (
  <main>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/AboutUs" component={AboutUs} />
      <Route path="/Credits" component={Credits} />
      <Route path="/creditoRevolvente" component={CreditoRevolvente} />
      <Route path="/creditoPuente" component={CreditoPuente} />
      <Route path="/creditoLiquidez" component={CreditoLiquidez} />
      <Route path="/ContactUs" component={ContactUs} />
      <Route path="/PrivacyNotice" component={PrivacyNotice} />
      <Route path="/FinancialEntitiesBuroInfo" component={FinancialEntitiesBuroInfo} />
      <Route component={NotFound} />
    </Switch>
  </main>
);

export default Main;

import React from 'react';
import PropTypes from 'prop-types';
import AntForm from '../AntForm';
import { SimRequestInputs } from '../../../utils/form_inputs/inputsSimRequest.jsx';
import { calculateQuotation } from '../../../utils/funcHelpers';
import './_style.scss';

const {
    simRequest: { mount, term, estimatedMount }
} = require('../../../utils/form_inputs/inputsTexts');

const trackedInputs = [mount.name, term.name];

const SimForm = ({
  storedValues,
  formRef,
  noSubmitButton,
  handleSubmit,
  toolTipClassName
}) => {
  const mountInitialValue = SimRequestInputs.find(i => i.name === mount.name).initialValue;
  const termInitialValue = SimRequestInputs.find(i => i.name === term.name).initialValue;

  const changesimulationRequest = (field, value) => {
    const complementField = trackedInputs.find(input => input !== field);
    const complementFieldValue = formRef.current.form.getFieldValue(complementField);
    const result = resolveQuotation({ [field]: value, [complementField]: complementFieldValue });
    formRef.current.form.setFieldsValue({ [estimatedMount.name]: result });
  };

  const resolveQuotation = values => calculateQuotation(values[mount.name], values[term.name]);

  const simFormInputs = SimRequestInputs.map(input => {
    if (trackedInputs.includes(input.name)) {
      //input.onChange = value => changeQuotationRequest(input.name, value);
      if (toolTipClassName)
        input.getTooltipPopupContainer = () => {
          const inputsSliders = document.getElementsByClassName(input.name);
          const inputSlider =
            // eslint-disable-next-line no-nested-ternary
            inputsSliders.length === 1
              ? inputsSliders[0]
              : toolTipClassName === 'inferiorIndex'
              ? inputsSliders[0]
              : inputsSliders[1];
          const newDiv = document.createElement('div');
          newDiv.className = toolTipClassName;
          return inputSlider.appendChild(newDiv);
        };
    }
    if (!storedValues && input.name === estimatedMount.name)
      input.initialValue = resolveQuotation({ [mount.name]: mountInitialValue, [term.name]: termInitialValue});
    if (storedValues) input.initialValue = storedValues[input.name];
    return input;
  });

  return (
    <AntForm
      wrappedComponentRef={formRef}
      inputs={simFormInputs}
      noSubmitButton={noSubmitButton}
      handleSubmit={handleSubmit}
      submitText="Solicita tu Crédito"
      submitTheme="ThemePrimary simular_Form"
      pClass = "simular_Form"
  
    />
  );
};

SimForm.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })])
    .isRequired,
  handleSubmit: PropTypes.func,
  storedValues: PropTypes.shape(PropTypes.object),
  noSubmitButton: PropTypes.bool,
  toolTipClassName: PropTypes.string
};

SimForm.defaultProps = {
  storedValues: undefined,
  noSubmitButton: false,
  handleSubmit: () => {},
  toolTipClassName: undefined
};

export default SimForm;

import React from 'react';
import { withRouter } from 'react-router-dom';
import Header from './components/molecules/Header/Header';
import Footer from './components/molecules/Footer/Footer';
import Main from './Main';

const App = () => (
  <div>
    <Header />
    <Main />
    <Footer />
  </div>
);

export default withRouter(App);

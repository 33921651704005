import React from 'react';
import { Form, Input, Icon, Slider, InputNumber } from 'antd';
import { iconColor } from './inputsFormats';
import { CustomField, TermMarks, MountMarks } from '../components/atoms/formsAtoms/formsAtoms';

const termSliderMarks = {
  12: <TermMarks value={12} />,
  60: <TermMarks value={60} />
};

const mountSliderMarks = {
  300000: <MountMarks value="300,000" />,
  15000000: <MountMarks value="15,000,000" />
};

const AntComponentTraductor = {
  [undefined]: Input,
  Text: Input.TextArea,
  Number: InputNumber,
  Slider,
  Custom: CustomField
};

const SliderMarksTraductor = {
  [undefined]: undefined,
  termSliderMarks,
  mountSliderMarks
};

export const inputRenderer = (inputs, form, submittedCount) => {
  const { getFieldDecorator } = form;
  return inputs.map(({ formItem, ...input }) => {
    const {
      content,
      classNameDiv,
      name,
      key,
      rules,
      initialValue,
      label,
      toolTip,
      iconType,
      component,
      hasFeedback,
      marks,
      ...inputProps
    } = input;

    const AntComponent = AntComponentTraductor[component];

    const touched = form.touched && form.touched[name];
    const hasError = form.errors && form.errors[name];
    const touchedError = hasError && touched;

    const prefix = () => {
      if (iconType) return <Icon type={iconType} style={{ color: iconColor }} />;
    };

    return (
      <div className={classNameDiv}>
        <Form.Item
          key={key}
          {...formItem}
          label={
            toolTip ? (
              <span>
                {label} &nbsp;
                {toolTip}
              </span>
            ) : (
              label
            )
          }
          hasFeedback={(hasFeedback && submittedCount) || (hasFeedback && touched)}
          help={submittedCount || touchedError ? hasError : false}
        >
          {getFieldDecorator(name, { rules, initialValue })(
            <AntComponent {...inputProps} prefix={prefix()} marks={SliderMarksTraductor[marks]} />
          )}
        </Form.Item>
      </div>
    );
  });
};

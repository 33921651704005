import React from 'react';
import PropTypes from 'prop-types';
import './_style.scss';

export const CustomField = ({ value }) => {
  const processedvalue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return (
    <div className="customTextCuota">
      <div className="textCuota">
        <span className="textValue">${processedvalue}</span>
      </div>
      <div className="baseCustomTextCuota" />
      <div className="infoText">
        CAT 24% informativo, costos y tasas pueden variar al formalizar la cotización y valoración
        crediticia.
      </div>
    </div>
  );
};

export const TermMarks = ({ value }) => (
  <div className="termSliderMarks">
    {value}
    <span className="small"> MESES</span>
  </div>
);

export const MountMarks = ({ value }) => (
  <div className="mountSliderMarks">
    <span className="small">$</span>
    {value}
  </div>
);

CustomField.propTypes = {
  value: PropTypes.string.isRequired
};
TermMarks.propTypes = {
  value: PropTypes.string.isRequired
};
MountMarks.propTypes = {
  value: PropTypes.string.isRequired
};

export const config = {
  apiKeys: {
    RECAPTCHA_SITE_KEY: '6LeE7LwUAAAAAG3c7jde4XKpaiOvVy4rU4zCPg14'
  },
  URLs: {
    Backend_Production: 'https://prestamosexpeditos.com.mx/api',
    Backend_Testing: 'http://prestamosexpeditos.atixlabs.com/api',
    Backend_Develop: 'http://prestamosexpeditos.atixlabs.com:8080/api'
  },
  annualInterest: 24
};

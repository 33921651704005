import React, { useState, useEffect } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Link } from 'react-router-dom';
import { contactUsInputs } from '../../../utils/form_inputs/inputsContactUs';
import AntForm from '../../molecules/AntForm';
import apiService from '../../../services/apiCalls/all';
import ButtonPrimary from '../../atoms/ButtonPrimary/ButtonPrimary';
import { config } from '../../../config/config';
import { scrollWindowToTop } from '../../../utils/funcHelpers';
import { PagesNames, documentName } from '../../../utils/constants';
import './_style.scss';

const {
  quotationModalMessages: { ContactUsMessages }
} = require('../../../utils/userMessages');

const ContactUs = ({ location }) => {
  useEffect(() => {
    document.title = `${PagesNames[location.pathname]} | ${documentName}`;
  });

  const [showContactRequestResult, setShowContactRequestResult] = useState(false);
  const [contactRequestError, setContactRequestError] = useState(false);

  useEffect(() => {
    scrollWindowToTop({ smooth: true });
  }, []);

  const sendContactRequest = async values => {
    try {
      await apiService().sendContactRequest(values);
      setShowContactRequestResult(true);
      setContactRequestError(false);
    } catch (err) {
      setShowContactRequestResult(true);
      setContactRequestError(true);
    }
    window.scrollTo(0, 0);
  };

  return (
    <div className="contactUsContainer" >
      <div className="titleContainer">
        <h1>Contáctanos</h1>
        {!showContactRequestResult && (
          <p>Completa estos datos de contacto para poder darte una respuesta:</p>
        )}
      </div>
      <div className={showContactRequestResult ? 'hidden' : undefined}>
        <AntForm
          inputs={contactUsInputs}
          handleSubmit={sendContactRequest}
          RECAPTCHA_SITE_KEY={config.apiKeys.RECAPTCHA_SITE_KEY}
          submitButtonClass="buttonSectionForm"
          submitTheme="unselected"
        />
      </div>
      {showContactRequestResult && (
        <div className="confirmationBox" id="ContactanosConfirmacion">
          <div className="imgArea">
            <img
              src={contactRequestError ? 'img/illusConfirmError.svg' : 'img/illusConfirm.svg'}
              alt=""
            />
          </div>
          <div className="messageArea">
            <div className="textArea">
              <h1>
                {contactRequestError
                  ? ContactUsMessages.errorResponse
                  : ContactUsMessages.successResponse}
              </h1>
              {contactRequestError && <h2>{ContactUsMessages.errorSubtitle}</h2>}
            </div>
          </div>
          {!!contactRequestError && (
            <div className="buttonArea">
              <ButtonPrimary
                onClick={() => setShowContactRequestResult(false)}
                text="Confirmar"
                theme="unselected"
              />
            </div>
          )}
          {!contactRequestError && (
            <div className="buttonArea">
              <Link to="/">
                <ButtonPrimary text="Volver al inicio" theme="unselected" />
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ContactUs.propTypes = {
  location: ReactRouterPropTypes.location.isRequired
};

export default ContactUs;

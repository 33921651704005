import React from 'react';
import PropTypes from 'prop-types';
import ButtonPrimary from '../../atoms/ButtonPrimary/ButtonPrimary';

const {
  quotationModalMessages: { Step3Messages }
} = require('../../../utils/userMessages');

const QuotationModalStep3 = ({ handleSubmit, showError }) => (
  <div className="confirmationBox" id={showError ? 'ErrorModal' :'ConfirmacionModal'} >
    <div className="messageArea">
      <div className="textArea">
        <h1>{showError ? Step3Messages.errorSubtitle : Step3Messages.subtitle}</h1>
        <h2>{showError ? Step3Messages.errorInfo : Step3Messages.info}</h2>
      </div>
      <div className="buttonArea">
        <ButtonPrimary onClick={handleSubmit} text="Confirmar" theme="unselected" />
      </div>
    </div>
    <div className="imgArea">
      <img src={showError ? 'img/illusConfirmError.svg' : 'img/illusConfirm.svg'} alt="" />
    </div>
  </div>
);

QuotationModalStep3.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  showError: PropTypes.bool
};

QuotationModalStep3.defaultProps = {
  showError: false
};

export default QuotationModalStep3;

import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

import './_style.scss';

const NavTranslate = {
  '/': 'home',
  '/AboutUs': 'aboutUs',
  '/Credits': 'credits'
};

const NavBar = () => (
  <div className="menuMobile">
    <img className="menuMobileHamburger" src="img/menu.svg" alt="menu" />
    <Menu
      selectedKeys={[NavTranslate[window.location.pathname]]}
      mode="horizontal"
      forceSubMenuRender
    >
      <Menu.Item key="home">
        <Link to="/">Inicio</Link>
      </Menu.Item>
      <Menu.Item key="aboutUs">
        <Link to="/AboutUs">Sobre Nosotros</Link>
      </Menu.Item>

      <Menu.SubMenu key="SubMenu" title="Créditos"  >
        <Menu.Item key="creditos">
          <Link to="/Credits">Pyme</Link>
        </Menu.Item>
        <Menu.Item key="creditoRevolvente">
          <Link to="/creditoRevolvente">Revolvente</Link>
        </Menu.Item>
        <Menu.Item key="creditoLiquidez">
          <Link to="/creditoLiquidez">Liquidez</Link>
        </Menu.Item>
        <Menu.Item key="creditoPuente">
          <Link to="/creditoPuente">Puente</Link>
        </Menu.Item>
        <Menu.Item key="autos">
          <a target="_blank" href="http://expediauto.com/index.php" rel="noopener noreferrer">Autos</a>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key="brokers">
        <a target="_blank" href="https://www.brokers.prestamosexpeditos.com/" rel="noopener noreferrer">Brokers</a>
      </Menu.Item>


    </Menu>
  </div>
);

export default NavBar;

import React, { useState, useRef } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import QuotationModalStep1 from '../QuotationModalWizard/QuotationModalStep1';
import QuotationModalStep2 from '../QuotationModalWizard/QuotationModalStep2';
import QuotationModalStep3 from '../QuotationModalWizard/QuotationModalStep3';
import { QuotationRequestInputs } from '../../../utils/form_inputs/inputsQuotatuionRequest';
import WizardFooter from '../WizardFooter';
import apiService from '../../../services/apiCalls/all';
import './_style.scss';

const {
  quotationModalMessages: {
    titles: { Step1, Step2, Step3 }
  }
} = require('../../../utils/userMessages');
const {
  QuotationEndpointExtraParams: { hasWarrantyParam }
} = require('../../../utils/constants');
const {
  quotationRequest: { mount, term, estimatedMount }
} = require('../../../utils/form_inputs/inputsTexts');

const titleTranslator = {
  0: Step1,
  1: Step2,
  2: Step3
};

const QuotationModal = ({ visible, onCancel, initValues }) => {
  const formRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [storedValues, setStoredValues] = useState();
  const [hasWarranty, setHasWarranty] = useState(true);
  const [quotationRequestError, setQuotationRequestError] = useState(false);

  const mountInitialValue = QuotationRequestInputs.find(i => i.name === mount.name).initialValue;
  const termInitialValue = QuotationRequestInputs.find(i => i.name === term.name).initialValue;

  const handleStepSubmit = async values => {
    const joinedValues = { ...storedValues, ...values };
    setStoredValues(joinedValues);
    if (currentStep === 1) {
      await sendQuotationRequest(joinedValues);
    }
    setCurrentStep(currentStep + 1);
  };

  const sendQuotationRequest = async values => {
    const processedData = processValues(values);
    try {
      await apiService().sendQuotationRequest(processedData);
    } catch (err) {
      setQuotationRequestError(true);
    }
  };

  const processValues = values => {
    const toProcessValues = Object.assign(values);
    delete toProcessValues[estimatedMount.name];
    return {
      ...toProcessValues,
      [term.name]: `${toProcessValues[term.name]}`,
      [hasWarrantyParam]: hasWarranty ? 'Si' : 'No'
    };
  };

  const handleBackwards = () => {
    setCurrentStep(currentStep - 1);
  };

  const submitForm = async () => {
    const { validCaptcha, captchaResult } = await formRef.current.preSubmit();
    await formRef.current.form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      if (!validCaptcha) return;
      const processedValues = processFormValues({ ...values, captchaResult });
      handleStepSubmit(processedValues);
    });
  };

  const processFormValues = values => ({
    ...values,
    [hasWarrantyParam]: hasWarranty
  });

  const cleanAndCancelModal = () => {
    if (formRef.current && formRef.current.from) {
      formRef.current.from.setFieldsValue({ [mount.name]: mountInitialValue });
      formRef.current.from.setFieldsValue({ [term.name]: termInitialValue });
    }
    setQuotationRequestError(false);
    setCurrentStep(0);
    setStoredValues(undefined);
    setHasWarranty(true);
    onCancel();
  };

  const stepContainer = {
    0: (
      <QuotationModalStep1
        formRef={formRef}
        storedValues={storedValues || initValues}
        hasWarranty={hasWarranty}
        toggleHasWarranty={() => setHasWarranty(!hasWarranty)}
      />
    ),
    1: <QuotationModalStep2 formRef={formRef} />,
    2: <QuotationModalStep3 handleSubmit={cleanAndCancelModal} showError={quotationRequestError} />
  };

  return (
    <Modal
      title={titleTranslator[currentStep]}
      visible={visible}
      onCancel={cleanAndCancelModal}
      footer={null}
      destroyOnClose
    >
      <div>
        {stepContainer[currentStep]}
        {currentStep !== 2 && (
          <WizardFooter
            handleFowards={submitForm}
            handleBackwards={currentStep === 1 && handleBackwards ? handleBackwards : undefined}
            hasBackwards={currentStep === 1}
            themeBackwards="unselected"
            themeFowards="unselected"
          />
        )}
      </div>
    </Modal>
  );
};

QuotationModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  initValues: PropTypes.shape(PropTypes.object)
};

QuotationModal.defaultProps = {
  visible: false,
  initValues: undefined
};

export default QuotationModal;

import React from 'react';
import ButtonPrimary from '../../atoms/ButtonPrimary/ButtonPrimary';

import './_style.scss';

const MapContainer = () => (
  <div className="mapSection">
    <div className="mapInfo">
      <h3>Nuestra dirección</h3>
      <p>
      Bufon 46 INT. 602 Col. Anzures,<br />
      Alcaldía Miguel Hidalgo  CDMX<br />
      C.P. 11590.
      </p>
      <h3>E-mail</h3>
      <a href="mailto:info@prestamosexpeditos.com.mx">info@prestamosexpeditos.com.mx</a>
      <br />
      <br />
      <h3>¡Llámanos!</h3> <p>Tel: (55) 4170 3700</p>
      <a href="tel:+52 55 4170 3700" data-content="+52 55 4170 3700" data-type="phone">
        <ButtonPrimary text="¡Llamar!" theme="ThemeSecondary BotonLlamar" pClass="BotonLlamar" />
      </a>
    </div>
    <div className="locationIcon">
      <img src="img/ilus_3.png" alt="Financiera Préstamos Expeditos en Buffon 46 INT. 602, Col. Anzures." title="Ícono de ubicación de Financiera Préstamos Expeditos" />
    </div>
    <div className="mapita">
      <iframe
        title="mapIframe"
        src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=%20Bufon%2046%20INT.%20602,%20Col.%20Anzures,%20Ciudad%20de%20M%C3%A9xico,%20CDMX.%20C.P.%2011590.+(Pr%C3%A9stamos%20Expeditos)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </div>
  </div>
);

export default MapContainer;

import axios from 'axios';
import { addQueryString } from '../../utils/urlsUtils';
import { config } from '../../config/config';

const BACKEND = config.URLs.Backend_Production;

const makeGetRequest = () => (url, queryParams) => {
  const endpointUrl = BACKEND + url;
  return axios
    .get(endpointUrl, {
      params: queryParams
    })
    .then(response => response.data);
};

const makePostRequest = () => (url, bodyParams = {}, queryParams) => {
  const endpointUrl = BACKEND + url;
  const completeUrl = addQueryString(endpointUrl, queryParams);
  return axios.post(completeUrl, { ...bodyParams }).then(response => response.data);
};

export default client => ({
  makeGetRequest: makeGetRequest(client),
  makePostRequest: makePostRequest(client)
});

import React from 'react';
import { NavLink } from 'react-router-dom';
import './_style.scss';

const Footer = () => (
  <div>
    <footer>
      <div className="contactInfo">
        <div className="content">
          <h3>CONTENIDO</h3>
          <NavLink exact to="/" activeClassName="active">
            Inicio
          </NavLink>
          <NavLink exact to="/AboutUs" activeClassName="active">
            Nosotros
          </NavLink>
          <NavLink exact to="/Credits" activeClassName="active">
            Créditos
          </NavLink>
          <NavLink exact to="/ContactUs" activeClassName="active">
            Contáctenos
          </NavLink>
        </div>
        <div className="contact">
          <h3>CONTACTO</h3>
          <p>
          Bufon 46 INT. 602 Col. Anzures,<br />
          Alcaldía Miguel Hidalgo  CDMX<br />
          C.P. 11590.
          </p>
          <p>Tel: (55) 4170 3700 </p>
          <a href="mailto:info@prestamosexpeditos.com.mx">info@prestamosexpeditos.com.mx</a>
        </div>
        <div className="general">
          <h3>GENERAL</h3>
          <NavLink exact to="/PrivacyNotice" activeClassName="active">
            Aviso de Privacidad
          </NavLink>
          <NavLink exact to="/FinancialEntitiesBuroInfo" activeClassName="active">
            ¿Qué es el Buró de Entidades Financieras?
          </NavLink>
        </div>
        <div className="redes">
          <div className="title">
            <h3>REDES SOCIALES</h3>
          </div>
          <div className="redesIcons">
            <a
              href="https://www.facebook.com/prestamosexpeditos/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="img/face.svg" alt="Préstamos Expeditos te ayuda a encontrar la solución para tu crédito. Contacto Facebook" title="Préstamos Expeditos. Logo de red social Facebook"  />
            </a>
            <a
              href="https://www.linkedin.com/company/pr%C3%A9stamos-expeditos/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="img/linkedin.svg"  alt="Préstamos Expeditos en redes sociales. Contacto Linkedin" title="Logo de red social Linkedin. Financiera Préstamos Expeditos"  />
            </a>
          </div>
        </div>
        <div className="logosFooter">
          <a href="https://www.gob.mx/condusef" target="_blank" rel="noreferrer noopener">
            <img src="img/consudef-logo.png" alt="Préstamos Expeditos registrados en Condusef con número de RECA: 3395-439-006495/03-01641-0419." title="Logo de Condusef"   />
          </a>
          <a href="https://www.buro.gob.mx/general_gob.php?id_sector=69&id_periodo=16" target="_blank" rel="noreferrer noopener">
            <img src="img/buro-logo.png" alt="Préstamo transparente, sin importar buró" title="Logo de Buró de crédito" class="imgBuro"/>
            <p>https://www.buro.gob.mx/</p>
          </a>
        </div>
      </div>
      <hr />
      <div className="legales">

       <p>
         PRESTAMOS EXPEDITOS SE HA ESFORZADO POR SER LA MEJOR OPCIÓN EN CRÉDITOS CON GARANTÍA HIPOTECARIA. EN MÉXICO TENEMOS MÁS DE 10 AÑOS DE EXPERIENCIA EN EL MERCADO DE LOS PRÉSTAMOS CON GARANTÍA INMOBILIARIA, CON LOS RECURSOS DEL CRÉDITO PODRÁS REFINANCIAR TUS DEUDAS Y OBTENER UNA TASA DE INTERÉS MENOR, TAMBIÉN PODRÁS DESTINAR EL DINERO DEL PRÉSTAMO DE LIQUIDEZ A INVERTIR EN TUS PROYECTOS; OCUPA TU VIVIENDA COMO AVAL PARA PODER OBTENER ESE PRÉSTAMO QUE TANTO NECESITAS, TE PODEMOS OTORGAR FINANCIAMIENTO DESDE $300,000 HASTA $15 MILLONES DE PESOS, OFRECEMOS PLAZOS FLEXIBLES QUE VAN DESDE LOS 12 MESES HASTA 60 MESES; EL BURÓ NO ES DETERMINANTE PARA OTORGAR TU CRÉDITO, SIENDO PERSONA FÍSICA O MORAL, CON O SIN ACTIVIDAD EMPRESARIAL. TODO EL PROCESO DE APROBACIÓN DEL PRÉSTAMO ES RÁPIDO Y FÁCIL.
       </p>


        <p>
          PRESTAMOS EXPEDITOS, S.A. DE C.V. SOFOM, E.N.R. ES UNA SOCIEDAD FINANCIERA DE OBJETO MÚLTIPLE, ENTIDAD NO REGULADA QUE PARA SU CONSTITUCIÓN Y OPERACIÓN CON TAL CARÁCTAR NO REQUIERE DE LA SECRETARÍA DE HACIENDA Y CRÉDITO PÚBLICO, ESTÁ SUJETA A LA SUPERVISIÓN DE LA COMISIÓN NACIONAL BANCARIA Y DE VALORES, ÚNICAMENTE PARA EFECTOS DE LO DISPUESTO POR EL ARTÍCULO 56 DE LA LEY GENERAL DE ORGANIZACIONES Y ACTIVIDADES AUXILIARES DEL CRÉDITO.
          <br />UNIDAD DE ATENCIÓN ESPECIALIZADA, PARA CUALQUIER DUDA QUEJA O SUGERENCIA, PONEMOS A SU DISPOSICIÓN NUESTRA UNIDAD DE ATENCIÓN ESPECIALIZADA A CLIENTES, DIR. EN BUFFON 46 INT. 602, COL. ANZURES, CIUDAD DE MÉXICO, CDMX, C.P. 11590 Y POR CORREO ELECTRÓNICO,{' '}
          <a href="mailto:contador@prestamosexpeditos.com.mx">
          CONTADOR@PRESTAMOSEXPEDITOS.COM.MX.
          </a>
        </p>
        <p>© 2024 por Préstamos Expeditos.</p>
      </div>
    </footer>
  </div>
);

export default Footer;

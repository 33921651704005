import React, { useState, useEffect, useRef } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Link } from 'react-router-dom';
import QuotationModal from '../../molecules/Modal/QuotationModal';
import QuotationForm from '../../molecules/QuotationForm/QuotationForm';
import SimForm from '../../molecules/SimForm/SimForm';
import ButtonPrimary from '../../atoms/ButtonPrimary/ButtonPrimary';
import TitlePrimary from '../../atoms/TitlePrimary/TitlePrimary';
import MapContainer from '../../organisms/MapContainer/MapContainer';
import { scrollWindowToTop } from '../../../utils/funcHelpers';
import { PagesNames, documentName } from '../../../utils/constants';
import { contactUsInputs } from '../../../utils/form_inputs/inputsContactUs';
import { Form, Input, Icon, Slider, InputNumber } from 'antd';
import { CustomField, TermMarks, MountMarks } from '../../atoms/formsAtoms/formsAtoms';
import './_style.scss';
import '../../../css/app.scss';

const Home = ({ location }) => {
  useEffect(() => {
    document.title = `Préstamos Expeditos | Financiamiento con garantía hipotecaria`;
  });

  const formRef = useRef(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [formValues, setFormValues] = useState(undefined);

  const termSliderMarks = {
    12: <TermMarks value={12} />,
    60: <TermMarks value={60} />
  };
  
  const mountSliderMarks = {
    300000: <MountMarks value="300,000" />,
    15000000: <MountMarks value="15,000,000" />
  };

  const millionFormater = value => {
    const valueStr = value.toString();
    const valueLen = valueStr.length;
    const noDecimals = value >= 10000000 ? valueStr.charAt(2) : valueStr.charAt(1);
    const valueMillion = valueStr.substring(0, valueLen - 4).replace(/\B(?=(\d{2})+(?!\d))/g, '.');
    const procesedValues =
      noDecimals === '0'
        ? valueMillion.substring(0, valueMillion.length - 3)
        : valueMillion.substring(0, valueMillion.length - 1);
    return value >= 1000000
      ? `$${procesedValues} M`
      : `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const submitQuotationRequest = values => {
    //setFormValues(values);
    setModalVisible(true);
  };

  useEffect(() => {
    scrollWindowToTop({ smooth: true });
  }, []);

  return (
    <div>
      <div className="containerHome">
        <div className="bannerSection">
          <div className="titleBanner">
            <h1>
              Crédito con garantía <br />
              hipotecaria
            </h1>
            <p className="subtitle">
              Préstamos desde <strong>$300,000</strong> hasta <strong>$15 millones</strong>.<br></br>
              Con o sin actividad empresarial, <strong>tasa desde 20% anual</strong>.<br></br>
              Pre-autorizamos tu crédito en 24-48 hrs.
           </p>
           <p>{formValues}</p>
          </div>
        </div>
        {modalVisible && (
          <QuotationModal
            visible={modalVisible}
            onCancel={() => setModalVisible(false)}
            initValues={formValues}
          />
        )}
        <div className="cotizador">
          <QuotationForm
            formRef={formRef}
            handleSubmit={submitQuotationRequest}
            toolTipClassName="inferiorIndex"
            formValues={formValues}
          />
        </div>
        <div className="backgroundMiscelanea">
          <div className="sectionCredit">
            <div className="puntos" />
            <div className="characteristics">
              <TitlePrimary textSmallTitle="➜ Crédito Pyme para tu negocio" title="Autorización de Crédito" />
              <p className="subtitle">
                Autorizamos tu crédito en menos de 24 horas, sin importar tu estatus en Buró de Crédito. Flexible con comprobante de ingresos. Obtén la liquidez para tu negocio, con o sin actividad empresarial.
              </p>
              <br />
              <ul>
                <li>
                  Te otorgamos un préstamo transparente, sin importar Buró.
                </li>
                <li>
                   Crédito de liquidez para persona moral o física con o sin actividad empresarial.
                 </li>
                <li>
                  Préstamos con garantía hipotecaria (casa, departamento o edificio). Con un valor del inmueble desde $1,800,000.
                </li>
                <li>
                    Todo el proceso del crédito es seguro, avalado por notario público.
                </li>

                <li>
                  Ofrecemos préstamos con garantía hipotecaria en Ciudad de México, Estado de México, Puebla, Querétaro, Pachuca y Cuernavaca.
                </li>
                <li>
                    Reutiliza tu línea de crédito cada que lo requieras, a partir del sexto mes.
                </li>
              </ul>
            </div>
            <div className="imgcharacteristics">
              <img src="img/imgCaract.png" alt="Financiera a tu medida. Créditos con garantía hipotecaria." title="Crédito Pyme, seguro y flexible" />
            </div>
          </div>
        </div>

        <div className="fondoPleno">
          <div className="creditBenefits">
            <div className="ilustrationBenefit">
              <img src="img/ilus_1.svg" alt="Préstamos desde $300,000 a $15,000,000. Sin importar Buró de Crédito." title="Préstamos a tu alcance" />
            </div>
            <div className="benefitText">
              <h2>Beneficios del Crédito</h2>

              <p className="primaryText">
                Al hipotecar tu casa obtienes la liquidez que necesitas. Autorizamos tu crédito en menos de 24 horas. Préstamos Expeditos es una Financiera flexible;
                Por lo cuál entendemos si tú historial no es perfecto.
              </p>


              <ButtonPrimary
                text="¡Solicitar!"
                theme="ThemePrimary cotizar_solicitar"
                onClick={() => setModalVisible(true)}
		pClass = "cotizar_solicitar"
              />
            </div>
          </div>

          <div className="sectionAboutUs">
            <div className="cardAboutUs">
              <div className="textContainer">
                <h2>Nosotros</h2>
                <p>
                  Somos una sociedad financiera de objeto múltiple (SOFOM) registrada en la Condusef,
                  contamos con más de 14 años de experiencia que avalan la seguridad de nuestras operaciones financieras.
                  Más de $500 millones en préstamos.
                </p>
                <Link to="/AboutUs">
                  <ButtonPrimary text="Nosotros" />
                </Link>
              </div>
              <img src="img/imgNosotros.png" alt="Préstamo flexible con comprobante de ingresos, persona física o moral." title="Préstamo flexible urgente" />

            </div>
          </div>

          <div className="contactSection">
            <div className="ilustracionContact">
              <img src="img/ilus_2.svg"  alt="Creditos con garantia hipotecaria a tu alcance" title="Creditos liquidez"  />
            </div>
            <div className="ContainerContact">
              <TitlePrimary
                textSmallTitle="➜ Contacto"
                title="Más información de créditos con aval hipotecario."
                theme="themeTitleColor"
              />
              <p>
                ¡Contáctanos! Queremos ayudarte a encontrar el préstamo ideal para ti o tu negocio, con gusto resolvemos tus dudas.
              </p>
              <Link to="/ContactUs">
                <ButtonPrimary text="Contacto" theme="ThemeSecondary" />
              </Link>
            </div>

          </div>
        </div>

          <div className="sim_title_div">
              <TitlePrimary
                  textSmallTitle="➜ Simula tu crédito"
                  title="Ver tabla de amortización."
                  theme="themeTitleColor"
                />
          </div>

          <div className="sim_container_out">


            <div className="ant-form ant-form-horizontal">

              <div class="form-full-row">

                <div className="form-col">
                  <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-label label-div">
                      <label for="monto_credito" class="ant-form-item-required ant-form-item-no-colon" title="¿CUÁNTO DINERO NECESITAS?">¿CUÁNTO DINERO NECESITAS?</label>
                    </div>

                    <Slider defaultValue={500000} tooltipVisible={true} marks={mountSliderMarks} min={300000} max={15000000} step={100000}  tipFormatter = {millionFormater} className="amount_slider" />
                  </div>
                </div>

                <div className="form-col">
                  <div className="ant-row ant-form-item">

                    <div className="ant-col ant-form-item-label label-div">
                      <label for="monto_credito" class="ant-form-item-required ant-form-item-no-colon" title="¿EN QUÉ TIEMPO PAGARÍAS?">¿EN QUÉ TIEMPO PAGARÍAS?</label>
                    </div>

                    <Slider defaultValue={12} tooltipVisible={true} marks={termSliderMarks} min={12} max={60} step={12} className="month_slider" toolTipClassName="inferiorIndex" 
                    tooltipClassName="inferiorIndex" tooltipClassname="inferiorIndex"/>
                  </div>
                </div>


                <div className="form-col">
                  <div className="ant-row ant-form-item">

                  <ButtonPrimary 
                    text="Simular"
                    type="primary"
                    theme="ThemePrimary"
                    onClick="{sim_function}"
                    htmlType="SimBtn"
                  />


                  </div>
                </div>


              </div>



              <div id="table_loan"></div>

            </div>

          </div>

          
      </div>
      

      <MapContainer />
    </div>



  );
};

Home.propTypes = {
  location: ReactRouterPropTypes.location.isRequired
};

export default Home;

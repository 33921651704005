import React from 'react';
import PropTypes from 'prop-types';
import './_style.scss';

const CardCredit = ({ imgCard, titleCard, textCard, theme }) => {
  const classname = 'CardCredit ' + theme;
  return (
    <div className={classname}>
      <img className="imgCard" src={imgCard} alt="" height="100px" />
      <h3 className="titleCard">{titleCard}</h3>
      <p className="textCard">{textCard}</p>
    </div>
  );
};

CardCredit.propTypes = {
  imgCard: PropTypes.string.isRequired,
  titleCard: PropTypes.string.isRequired,
  textCard: PropTypes.string,
  theme: PropTypes.string
};

CardCredit.defaultProps = {
  textCard: undefined,
  theme: undefined
};

export default CardCredit;

import React, { useEffect } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import CardMission from '../../molecules/CardMission/CardMission';
import TitlePrimary from '../../atoms/TitlePrimary/TitlePrimary';
import MapContainer from '../../organisms/MapContainer/MapContainer';
import { scrollWindowToTop } from '../../../utils/funcHelpers';
import { PagesNames, documentName } from '../../../utils/constants';
import './_style.scss';

const AboutUs = ({ location }) => {
  useEffect(() => {
    document.title = `${PagesNames[location.pathname]} | ${documentName}`;
  });

  useEffect(() => {
    scrollWindowToTop({ smooth: true });
  }, []);

  return (
    <div>
      <div className="containerAboutUs">
        <div className="bannerSection">
          <div className="titleBanner">
            <h1 className="mainTitle">Nosotros</h1>
            <p>
            Somos una sociedad financiera de objeto múltiple (SOFOM) registrada en la Condusef,
            contamos con más de 14 años de experiencia que avalan la seguridad de nuestras operaciones financieras. <strong>Más de $500 millones en préstamos.</strong>
            </p>
          </div>
        </div>
        <div className="Caracteristicas">
          <div className="titleArea">
            <TitlePrimary
              textSmallTitle="➜ Crédito Pyme para tu negocio"
              title="Características"
              theme="themeTitleColor"
            />
            <p className="subtitle">
              Crédito desde <strong>$300,000</strong> hasta <strong>$15 millones</strong>.
            </p>
          </div>
          <div className="caractImg">
            <img src="img/ilus_6.svg" alt="" />
          </div>
          <div className="textArea">
            <div className="CaractContent">
              <ul>
                <li>
                  Sin importar el estatus de <strong>Buró de Crédito</strong>.
                </li>
                <li>
                  Autorización en <strong>24 hrs.</strong> y entrega en{' '}
                  <strong>15 días hábiles</strong>.
                </li>
                <li>Para personas con o sin actividad empresarial.</li>
                <li>
                  En garantía un bien inmueble con valor desde <strong>$1,800,000</strong>.
                </li>
                <li>
                  Tasa de interés anual desde 20% hasta 36%.
                </li>
              </ul>
            </div>
            <div className="CaractContent">
              <ul>
                <li>
                  <strong>Ofrecemos préstamos con garantía hipotecaria</strong> en Ciudad de México, Estado de México, Puebla, Querétaro, Pachuca y Cuernavaca.

                </li>
                <li>Proceso avalado ante notario público.</li>
                <li>Comisión por apertura negociable. Comisión del 6% sobre el importe del crédito.</li>
                <li>
                  Registrados en <strong>CONDUSEF</strong> con número de{' '}
                  <strong>RECA: 3395-439-006495/03-01641-0419</strong>.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="CardCompanyMission">
          <div className="titleSection">
            <h1>Nuestro Compromiso Contigo</h1>
          </div>
          <div className="cardSection">
            <div className="col2">
              <CardMission
                imgCard="img/icon_1.png"
                titleCard="Valores"
                textCard="Responsabilidad - Honestidad -
        Confianza - Lealtad."
                theme="themeNormal"
              />
            </div>
            <div className="col1">
              <CardMission
                imgCard="img/icon_3.png"
                titleCard="Visión"
                textCard="Ser la primera SOFOM a nivel nacional con las mejores condiciones financieras, para otorgar créditos respaldados por bienes inmuebles, ofreciendo a nuestros clientes comodidad, flexibilidad y seguridad en nuestros servicios."
                theme="themeCardMissionHighlight"
              />
              <br />
              <br />
              <CardMission
                imgCard="img/icon_2.png"
                titleCard="Misión"
                textCard="Ofrecemos solución financiera segura, rápida y confiable, garantizando su seguridad económica a través de préstamos respaldados por bienes inmuebles."
                theme="themeCardMission"
              />
            </div>
          </div>
        </div>
      </div>
      <MapContainer />
    </div>
  );
};

AboutUs.propTypes = {
  location: ReactRouterPropTypes.location.isRequired
};

export default AboutUs;

import React, { useState, useEffect } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import CardCredit from '../../molecules/CardCredit/CardCredit';
import ButtonPrimary from '../../atoms/ButtonPrimary/ButtonPrimary';
import TitlePrimary from '../../atoms/TitlePrimary/TitlePrimary';
import QuotationModal from '../../molecules/Modal/QuotationModal';
import MapContainer from '../../organisms/MapContainer/MapContainer';
import { scrollWindowToTop } from '../../../utils/funcHelpers';
import { PagesNames, documentName } from '../../../utils/constants';
import './_style.scss';

const Credits = ({ location }) => {
  useEffect(() => {
    document.title = `${PagesNames[location.pathname]} | ${documentName}`;
  });

  useEffect(() => {
    scrollWindowToTop({ smooth: true });
  }, []);

  const [modalVisible, setModalVisible] = useState(false);

  return (
    <div>
      {modalVisible && (
        <QuotationModal visible={modalVisible} onCancel={() => setModalVisible(false)} />
      )}
      <div className="sectionCredits">
        <div className="bannerSection">
          <div className="titleBanner">
            <h1 className="mainTitle">Crédito con garantía hipotecaria</h1>
            <ul>
              <li>
                Sin importar el estatus de <strong>Buró de Crédito</strong>.
              </li>
              <li>Para personas con o sin actividad empresarial.</li>
              <li><strong>Tasa desde 20% anual.</strong></li>
            </ul>
          </div>
        </div>
        <div className="titleSection">
          <h1 className="mainTitle">
            Autorización de crédito <br /> en 24 horas
          </h1>
          <p>
            Autorizamos tu crédito en menos de <strong>24 horas</strong> desde{' '}
            <strong>$300,000</strong> hasta <strong>$15 millones</strong>.{' '}
          </p>
        </div>
        <div className="puntos" />
        <div className="puntos2" />
        <div className="CardCreditSection">
          <div className="Container">
            <CardCredit
              imgCard="img/iconCard_01.svg"
              titleCard="Trámite Express"
              textCard="Tasa de interés variable en saldos insolutos."
            />
          </div>
          <div className="Container">
            <CardCredit
              imgCard="img/iconCard_02.svg"
              titleCard="Indicador Financiero"
              textCard="Monto del crédito desde $300,000 hasta $15 millones."
              theme="themeCardCredit"
            />
          </div>
          <div className="Container">
            <CardCredit
              imgCard="img/iconCard_03.svg"
              titleCard="Garantía Financiera"
              textCard="Pagos mensuales."
            />
          </div>
          <div className="Container">
            <CardCredit
              imgCard="img/iconCard_04.svg"
              titleCard="Estabilidad total"
              textCard="El plazo del crédito
es de 1 a 5 años."
              theme="themeCardCredit"
            />
          </div>
        </div>
        <div className="buttonSection">
          <ButtonPrimary
            text="¡Solicitar!"
            theme="ThemePrimary"
            onClick={() => setModalVisible(true)}
          />
        </div>
        <div className="banerRotativo">
          <div className="textArea">
            <div className="bannerContent">
              <TitlePrimary textSmallTitle="_ Crédito Pyme" title="Características y Requisitos" />
              <p className="subtitle">
                Crédito desde <strong>$300,000</strong> hasta <strong>$15 millones</strong>.
              </p>
              <ul>
                <li>Dirigido a pequeñas y medianas empresas.</li>
                <li>Línea revolvente a partir del 6to mes.</li>
                <li>
                  Tasa de interés anual del 20% al 36% fija, sobre saldos insolutos, según el
                  perfil.
                </li>
                <li>Plazo de 12 a 60 meses.</li>
                <li>CAT promedio 49.0%.</li>
                <li>Solicitud para análisis. Información al Tel. (55) 4170 3700 </li>
                <li>
                  Valoramos el inmueble sí envía la dir., metros de terreno y construcción, fotos de
                  la fachada en el interior y exterior con respuesta en 24 hrs.
                </li>
                <li>Entrega de check list para inicio de trámites.</li>
                <li>
                  Una vez documentado el proyecto, firma ante notario y fondeo de crédito en 15 días
                  hábiles.
                </li>
              </ul>
              <div className="buttonSection">
                <ButtonPrimary
                  text="¡Solicitar!"
                  theme="ThemeWhite"
                  onClick={() => setModalVisible(true)}
                />
              </div>
            </div>
            <div className="bannerImg">
              <img src="img/ilus_4.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="SimuladorPyme">
          <div className="illusArea">
            <img src="img/ilus_5.png" alt="" />
          </div>
          <div className="textArea">
            <h1>Simulador PYME</h1>
            <p>
              Al solicitar un crédito de un millon a plazo de 36 meses, con un interés mensual
              de 2.5%, tu último pago será de $28,464.33 sobre saldos insolutos, con un CAT promedio
              de 49.0%.
              Fecha de calculo del CAT: Mayo 2021.
            </p>
          </div>
        </div>
      </div>
      <MapContainer />
    </div>
  );
};

Credits.propTypes = {
  location: ReactRouterPropTypes.location.isRequired
};

export default Credits;

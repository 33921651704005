module.exports = {
  quotationModalMessages: {
    titles: {
      Step1: '¡Solicita tu crédito hoy!',
      Step2: '¡Regístrate y obtén tu crédito!',
      Step3: undefined
    },
    Step1Messages: {
      step: 'Paso 1',
      subtitle: 'Selecciona el tiempo y monto de tu crédito. Continúa con tu solicitud:',
      info: '¿Cuentas con un bien inmueble en garantía?'
    },
    Step2Messages: {
      step: 'Paso 2',
      subtitle: 'Completa tus datos. Recibe respuesta inmediata:',
      info: 'Completa los siguientes campos:'
    },
    Step3Messages: {
      subtitle: '¡Tu solicitud se ha enviado con éxito!',
      errorSubtitle: 'Ha ocurrido un error al enviar tu solicitud',
      info: '¡Te envíamos un e-mail a tu correo!',
      errorInfo: 'Intenta enviarlo nuevamente'
    },
    ContactUsMessages: {
      successResponse: '¡Tu información se ha enviado con éxito!',
      errorResponse: 'Ha ocurrido un error al cargar tus datos.',
      errorSubtitle: 'Por favor, intenta enviarlo nuevamente. ¡Gracias!'
    }
  }
};

module.exports = {
  QuotationEndpointExtraParams: {
    hasWarrantyParam: 'Cuenta_con_Garant_a',
    layout: 'Layout',
    leadSource: 'Lead_Source',
    processFlow: '$process_flow'
  },
  PagesNames: {
    '/': 'Préstamos Expeditos | Financiamiento con garantía hipotecaria',
    '/AboutUs': 'Nosotros',
    '/ContactUs': 'Contacto',
    '/Credits': 'Créditos',
    '/FinancialEntitiesBuroInfo': 'Buró de Crédito',
    '/PrivacyNotice': 'Aviso de Privacidad',
    NotFound: '404'
  },
  documentName: 'Préstamos Expeditos'
};

import React from 'react';
import PropTypes from 'prop-types';
import ButtonPrimary from '../../atoms/ButtonPrimary/ButtonPrimary';
import QuotationForm from '../QuotationForm/QuotationForm';

const {
  quotationModalMessages: { Step1Messages }
} = require('../../../utils/userMessages');

const QuotationModalStep1 = ({ toggleHasWarranty, hasWarranty, storedValues, formRef }) => (
  <div id="Formulario_credito">
    <p className="SmallTextModal">{Step1Messages.step}</p>
    <p className="MediumTextModal">{Step1Messages.subtitle}</p>
    <QuotationForm
      formRef={formRef}
      storedValues={storedValues}
      noSubmitButton
      toolTipClassName="superiorIndex"
    />
    <p className="garantia">{Step1Messages.info}</p>
    <div className="buttonModal">
      <ButtonPrimary
        onClick={() => toggleHasWarranty()}
        disabled={hasWarranty}
        theme={hasWarranty ? 'selected' : 'unselected'}
        text="Si"
      />
      <ButtonPrimary
        onClick={() => toggleHasWarranty()}
        disabled={!hasWarranty}
        theme={hasWarranty ? 'unselected' : 'selected'}
        text="No"
      />
    </div>
  </div>
);

QuotationModalStep1.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })])
    .isRequired,
  toggleHasWarranty: PropTypes.func.isRequired,
  hasWarranty: PropTypes.bool.isRequired,
  storedValues: PropTypes.shape(PropTypes.object)
};

QuotationModalStep1.defaultProps = {
  storedValues: undefined
};

export default QuotationModalStep1;

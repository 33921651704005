const { requiredField, emailRule, phoneRule } = require('../validationRules');
const {
  contactUs: { firstName, lastName, phoneNumber, email, message }
} = require('./inputsTexts');

export const contactUsInputs = [
  {
    key: firstName.name,
    name: firstName.name,
    placeholder: firstName.placeholder,
    label: firstName.label,
    type: 'string',
    rules: [requiredField],
    classNameDiv: firstName.name,
    formItem: {}
  },
  {
    key: lastName.name,
    name: lastName.name,
    placeholder: lastName.placeholder,
    classNameDiv: lastName.name,
    label: lastName.label,
    type: 'string',
    rules: [requiredField],
    formItem: {}
  },
  {
    key: phoneNumber.name,
    name: phoneNumber.name,
    placeholder: phoneNumber.placeholder,
    classNameDiv: phoneNumber.name,
    label: phoneNumber.label,
    type: 'string',
    rules: [requiredField, phoneRule],
    formItem: {}
  },
  {
    key: email.name,
    name: email.name,
    classNameDiv: email.name,
    label: email.label,
    placeholder: email.placeholder,
    type: 'string',
    rules: [requiredField, emailRule],
    iconType: 'mail',
    formItem: {}
  },
  {
    key: message.name,
    name: message.name,
    classNameDiv: message.name,
    label: message.label,
    placeholder: message.placeholder,
    component: 'Text',
    type: 'string',
    rules: [requiredField],
    formItem: {},
    autosize: { minRows: 2, maxRows: 6 }
  }
];

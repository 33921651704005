import React from 'react';
import PropTypes from 'prop-types';
import ButtonPrimary from '../atoms/ButtonPrimary/ButtonPrimary';
import './Modal/_style.scss';

const WizardFooter = ({
  FowardsText,
  BackwardsText,
  handleFowards,
  handleBackwards,
  themeFowards,
  themeBackwards,
  hasBackwards
}) => (
  <div className="buttonNextBack">
    <div className="backwardButton">
      {hasBackwards && (
        <ButtonPrimary onClick={handleBackwards} text={BackwardsText} theme={themeBackwards} />
      )}
    </div>
    <ButtonPrimary onClick={handleFowards} text={FowardsText} theme={themeFowards} />
  </div>
);

WizardFooter.propTypes = {
  FowardsText: PropTypes.string,
  BackwardsText: PropTypes.string,
  handleFowards: PropTypes.func.isRequired,
  handleBackwards: PropTypes.func,
  hasBackwards: PropTypes.bool,
  themeFowards: PropTypes.string,
  themeBackwards: PropTypes.string
};

WizardFooter.defaultProps = {
  FowardsText: 'siguiente',
  BackwardsText: 'anterior',
  handleBackwards: () => {},
  hasBackwards: false,
  themeFowards: undefined,
  themeBackwards: undefined
};

export default WizardFooter;

import React, { useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter, Link } from 'react-router-dom';
import ButtonPrimary from '../../atoms/ButtonPrimary/ButtonPrimary';
import QuotationModal from '../Modal/QuotationModal';
import NavBar from '../navBar/NavBar';

import './_style.scss';

const Header = ({ history, location }) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <header>
      <div className="logo">
        <Link to="/">
          <img src="img/logo-01.svg" height="65px" alt="Logo de Financiera Préstamos Expeditos que ofrece créditos Pyme, sin importar Buró." title="Logo de Financiera Préstamos Expeditos" />
        </Link>
      </div>
      <div className="NavBarContainer">
        <NavBar />
      </div>
      <div className="buttonSection" id="Cotizar_Header" >
        <ButtonPrimary text="cotizar" theme="ThemePrimary cotizar_header" onClick={() => setModalVisible(true)} pClass="cotizar_header" />
      </div>
      {modalVisible && (
        <QuotationModal
          visible={modalVisible}
          onCancel={() => {
            setModalVisible(false);
            history.push(location.pathname);
          }}
        />
      )}






    </header>
  );
};

Header.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired
};

export default withRouter(Header);

import React, { useState, useImperativeHandle } from 'react';
import { Form } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import ButtonPrimary from '../atoms/ButtonPrimary/ButtonPrimary';
import { inputRenderer } from '../../utils/formHelper';

const AntFormInputs = React.forwardRef((props, ref) => {
  const {
    handleSubmit,
    handleErrorSubmit,
    inputs,
    form,
    submitText,
    submitTheme,
    disabled,
    noSubmitButton,
    RECAPTCHA_SITE_KEY,
    submitButtonClass,
    resetOnSubmit,
    pClass
  } = props;

  const [submittedCount, setSubmittedCount] = useState(0);
  const [captchaResult, setCaptchaResult] = useState();
  const [captchaWarning, setCaptchaWarning] = useState(false);

  const formItems = inputRenderer(inputs, form, submittedCount);

  const processEventValues = event => {
    setSubmittedCount(submittedCount + 1);
    if (event) event.preventDefault();
    if (RECAPTCHA_SITE_KEY && !captchaResult) return setCaptchaWarning(true);
    setCaptchaWarning(false);
    form.validateFieldsAndScroll((err, values) =>
      err ? handleErrorSubmit(values) : onSubmit({ ...values, captchaResult })
    );
  };

  const onSubmit = toSubmitData => {
    if (resetOnSubmit) form.resetFields();
    handleSubmit(toSubmitData);
  };

  const preSubmit = () => {
    const validCaptcha = !(RECAPTCHA_SITE_KEY && !captchaResult);
    setSubmittedCount(submittedCount + 1);
    // eslint-disable-next-line no-unused-expressions
    validCaptcha ? setCaptchaWarning(false) : setCaptchaWarning(true);
    return { validCaptcha, captchaResult };
  };

  useImperativeHandle(ref, () => ({
    form,
    preSubmit
  }));

  return (
    <Form onSubmit={processEventValues} colon={false} from={form}>
      {formItems}
      {RECAPTCHA_SITE_KEY && (
        <div className="captcha">
          <Form.Item>
            <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={value => setCaptchaResult(value)} />
            {captchaWarning && <p className="CaptchaText">*Complete la verificacion por captcha</p>}
          </Form.Item>
        </div>
      )}
      {!noSubmitButton && (
        <div className={submitButtonClass}>
          <Form.Item>
            <ButtonPrimary
              type="primary"
              htmlType="submit"
              text={submitText}
              disabled={disabled}
              theme={submitTheme}
	     pClass = {pClass}
            />
          </Form.Item>
        </div>
      )}
    </Form>
  );
});

AntFormInputs.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.object).isRequired,
  form: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })])
    .isRequired,
  handleSubmit: PropTypes.func,
  handleErrorSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  submitText: PropTypes.string,
  submitTheme: PropTypes.string,
  noSubmitButton: PropTypes.bool,
  RECAPTCHA_SITE_KEY: PropTypes.string,
  submitButtonClass: PropTypes.string,
  resetOnSubmit: PropTypes.bool
};

AntFormInputs.defaultProps = {
  submitButtonClass: 'buttonSection',
  noSubmitButton: false,
  disabled: false,
  submitText: 'aceptar',
  submitTheme: undefined,
  handleErrorSubmit: () => {},
  handleSubmit: () => {},
  RECAPTCHA_SITE_KEY: undefined,
  resetOnSubmit: false
};

const AntForm = Form.create()(AntFormInputs);
export default AntForm;

module.exports = {
  contactUs: {
    firstName: { name: 'First_Name', label: 'NOMBRE', placeholder: 'NOMBRE' },
    lastName: { name: 'Last_Name', label: 'APELLIDO', placeholder: 'APELLIDO' },
    phoneNumber: {
      name: 'Phone',
      label: 'TELÉFONO DE CONTACTO',
      placeholder: 'INGRESE SU TELÉFONO AQUÍ'
    },
    email: { name: 'email', label: 'E-MAIL', placeholder: 'INGRESE SU E-MAIL' },
    message: { name: 'message', label: 'MENSAJE', placeholder: 'INGRESE SU MENSAJE AQUÍ' }
  },
  quotationRequest: {
    mount: { name: 'Monto_de_Cr_dito', label: '¿CUÁNTO DINERO NECESITAS?' },
    term: { name: 'Plazo', label: '¿EN QUÉ TIEMPO PAGARÍAS?' },
    estimatedMount: { name: 'Monto_estimado', label: 'SÓLO PAGA MENSUALMENTE:' }
  },
  modalQuotationRequest: {
    firstName: { name: 'First_Name', label: 'NOMBRE' },
    lastName: { name: 'Last_Name', label: 'APELLIDO' },
    phoneNumber: { name: 'Phone', label: 'CELULAR (RECIBIRÁS UN MENSAJE DE WHATSAPP)', placeholder:'(55) 8376 7677' },
    email: { name: 'Email', label: 'E-MAIL', placeholder:'micorreo@ejemplo.com' }
  },
  
  
  simRequest: {
    mount: { name: 'monto_simular', label: '¿CUÁNTO DINERO NECESITAS?' },
    term: { name: 'plazo_simular', label: '¿EN QUÉ TIEMPO PAGARÍAS?' },
    estimatedMount: { name: 'monto_simulado', label: 'SÓLO PAGA MENSUALMENTE:' }
  },
};

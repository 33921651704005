import { config } from '../config/config';

const monthlyInterest = config.annualInterest / 1200;

export const calculateQuotation = (mount, term) =>
  Math.round(calculatePmt(monthlyInterest, term, -mount) * 100) / 100;

const calculatePmt = (ir, np, pv, fv = 0, type = 0) => {
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  let pmt;

  if (ir === 0) return -(pv + fv) / np;

  const pvif = (1 + ir) ** np;
  pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + ir;

  return pmt;
};

export const scrollWindowToTop = ({ smooth }) =>
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: smooth ? 'smooth' : undefined
  });

const { requiredField, emailRule, phoneRule } = require('../validationRules');
const {
  simRequest: { mount, term, estimatedMount },
  modalQuotationRequest: { firstName, lastName, phoneNumber, email }
} = require('./inputsTexts');

const millionFormater = value => {
  const valueStr = value.toString();
  const valueLen = valueStr.length;
  const noDecimals = value >= 10000000 ? valueStr.charAt(2) : valueStr.charAt(1);
  const valueMillion = valueStr.substring(0, valueLen - 4).replace(/\B(?=(\d{2})+(?!\d))/g, '.');
  const procesedValues =
    noDecimals === '0'
      ? valueMillion.substring(0, valueMillion.length - 3)
      : valueMillion.substring(0, valueMillion.length - 1);
  return value >= 1000000
    ? `$${procesedValues} M`
    : `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const SimRequestInputs = [
  {
    key: mount.name,
    placeholder: mount.placeholder,
    name: mount.name,
    classNameDiv: mount.name,
    className: 'Slider',
    label: mount.label,
    component: 'Slider',
    min: 300000,
    max: 15000000,
    initialValue: 500000,
    step: 100000,
    tipFormatter: millionFormater,
    marks: 'mountSliderMarks',
    tooltipVisible: true,
    rules: [requiredField],
    formItem: {}
  },
  {
    key: term.name,
    placeholder: term.placeholder,
    name: term.name,
    classNameDiv: term.name,
    className: 'Slider',
    label: term.label,
    component: 'Slider',
    min: 12,
    max: 60,
    initialValue: 24,
    step: 12,
    marks: 'termSliderMarks',
    tooltipVisible: true,
    rules: [requiredField],
    formItem: {}
  },
  
];

import React from 'react';
import PropTypes from 'prop-types';
import './_style.scss';

const CardMission = ({ imgCard, titleCard, textCard, theme }) => {
  const classname = 'CardMission ' + theme;
  return (
    <div className={classname}>
      <div className="titleImgContainer">
        <img className="imgCard" src={imgCard} alt="" />
        <h3 className="titleCard">{titleCard}</h3>
      </div>
      <p className="textCard">{textCard}</p>
    </div>
  );
};

CardMission.propTypes = {
  imgCard: PropTypes.string.isRequired,
  titleCard: PropTypes.string.isRequired,
  textCard: PropTypes.string,
  theme: PropTypes.string
};

CardMission.defaultProps = {
  textCard: undefined,
  theme: undefined
};

export default CardMission;

import Helpers from './helpers';

const sendQuotationRequest = makePostRequest => data => makePostRequest('/Leads', { data });

const sendContactRequest = makePostRequest => data => makePostRequest('/contact', { data });

export default client => {
  const { makePostRequest } = Helpers(client);
  return {
    sendQuotationRequest: sendQuotationRequest(makePostRequest),
    sendContactRequest: sendContactRequest(makePostRequest)
  };
};

import React, { useEffect } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Link } from 'react-router-dom';
import ButtonPrimary from '../../atoms/ButtonPrimary/ButtonPrimary';
import TitlePrimary from '../../atoms/TitlePrimary/TitlePrimary';
import MapContainer from '../../organisms/MapContainer/MapContainer';
import { scrollWindowToTop } from '../../../utils/funcHelpers';
import { PagesNames, documentName } from '../../../utils/constants';
import './_style.scss';

const PrivacyNotice = ({ location }) => {
  useEffect(() => {
    document.title = `${PagesNames[location.pathname]} | ${documentName}`;
  });

  useEffect(() => {
    scrollWindowToTop({ smooth: true });
  }, []);

  return (
    <div className="containerPrivacy">
      <div className="bannerSection">
        <img src="img/lock.svg" alt="" />
        <h2>
          Aviso de <br />
          Privacidad
        </h2>
      </div>
      <div className="titleSection">
        <h1>Préstamos Expeditos S.A. de C.V. SOFOM E.N.R.</h1>
      </div>
      <div className="textSection primaryText">
        <div className="columna">
          <p>
            De acuerdo con lo previsto en la{' '}
            <strong>Ley Federal de protección de datos personales</strong>, PRÉSTAMOS EXPEDITOS S.A.
            DE C.V. SOFOM E.N.R. declara ser una empresa legalmente constituida de conformidad con
            las leyes mexicanas, con domicilio ubicado en, Buffon no. 46, despacho 602 piso 6, Col.
            Anzures, C.P. 11590, Del. Miguel Hidalgo, México, CDMX., manifiesta ser la responsable
            del tratamiento de sus datos personales.
          </p>
          <p>
            Oficina privada ubicada en diferente domicilio, el cual se encuentra en Buffon No. 46,
            despacho 602, piso 6, Col. Anzures, C.P. 11590, Del. Miguel Hidalgo, México, CDMX,
            teléfono de la oficina privada, 5254 8229.
          </p>
        </div>
        <div className="columna">
          <h2>Definiciones:</h2>
          <p>
            <strong>Datos personales:</strong> Cualquier información concerniente a una persona
            física identificada o identificable.
          </p>
          <p>
            <strong>Titular:</strong> La persona física (TITULAR) a quien identifica o corresponden
            los datos personales.
          </p>
          <p>
            <strong>Responsable:</strong> Persona física o moral de carácter privado que decide
            sobre el tratamiento de los datos personales.
          </p>
          <p>
            <strong>Tratamiento:</strong> La obtención, uso (que incluye el acceso, manejo,
            aprovechamiento, transferencia o disposición de datos personales), divulgación o
            almacenamiento de datos personales por cualquier medio.
          </p>
          <p>
            <strong>Transferencia:</strong> Toda comunicación de datos realizada a persona distinta
            del responsable o encargado del tratamiento.
          </p>
          <p>
            <strong>Derechos ARCO:</strong> Derechos de acceso, rectificación, cancelación y
            oposición.
          </p>
          <p>
            <strong>Consentimiento Tácito:</strong> Se entenderá que el titular ha consentido en el
            tratamiento de los datos, cuando habiéndose puesto a su disposición el Aviso de
            Privacidad, no manifieste su oposición.
          </p>
          <p>
            <strong>Fines de la información:</strong> Sus datos personales serán utilizados para los
            siguientes fines: fiscales. personales y bancarios.
          </p>
        </div>
      </div>
      <div className="banner2">
        <div className="textSection">
          <h2>Registrados en CONDUSEF</h2>
          <p>
            Registrados en CONDUSEF con número de RECA, <br />
            3395-439-006495/03-01641-0419.
          </p>
        </div>
        <div className="logoSection">
          <a href="https://www.gob.mx/condusef" target="_blank" rel="noreferrer noopener">
            <img src="img/consudef-logo-n.png" alt="" />
          </a>
          <a
            href="https://www.buro.gob.mx/general_gob.php?id_sector=69&id_periodo=16"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="img/buro-logo.png" alt="" />
          </a>
        </div>
      </div>
      <div className="contactSection">
        <div className="ilustracionContact">
          <img src="img/ilus_2.svg" alt="" />
        </div>
        <div className="ContainerContact">
          <TitlePrimary
            textSmallTitle="_ Contacto"
            title="¿Más Información?"
            theme="themeTitleColor"
          />
          <p>
            ¡Contáctanos! Queremos ayudarte a encontrar la solución para ti, con gusto resolvemos
            todas tus dudas.
          </p>
          <Link to="/AboutUs">
            <ButtonPrimary text="Contacto" theme="ThemeSecondary" />
          </Link>
        </div>
      </div>
      <MapContainer />
    </div>
  );
};

PrivacyNotice.propTypes = {
  location: ReactRouterPropTypes.location.isRequired
};

export default PrivacyNotice;

import React, { useEffect } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { scrollWindowToTop } from '../../../utils/funcHelpers';
import { PagesNames, documentName } from '../../../utils/constants';
import './_style.scss';

const FinancialEntitiesBuroInfo = ({ location }) => {
  useEffect(() => {
    document.title = `${PagesNames[location.pathname]} | ${documentName}`;
  });

  useEffect(() => {
    scrollWindowToTop({ smooth: true });
  }, []);

  return (
    <div className="containerBuro">
      <div className="textBuro">
        <h1>¿Qué es el Buró de Entidades Financieras?</h1>
        <p className="primary-text">
          El <strong>Buró de Entidades Financieras</strong> es una herramienta de consulta y
          difusión con la que podrás conocer los productos que ofrecen las entidades financieras,
          sus comisiones y tasas, las reclamaciones de los usuarios, las prácticas no sanas en que
          incurren, las sanciones administrativas que les han impuesto, las cláusulas abusivas de
          sus contratos y otra información que resulte relevante para informarte sobre su desempeño.
        </p>
        <br />
        <p className="primary-text">
          La siguiente información corresponde únicamente a la
          información publicada en el <strong>Buró de Entidades Financieras</strong> sobre Préstamos
          Expeditos S.A. de C.V. Sofom E.N.R. Si deseas conocer toda la información de todo el
          sector lo puedes acceder al sitio{' '}
          <a href="http://www.buro.gob.mx" target="_blank" rel="noreferrer noopener">
            www.buro.gob.mx.
          </a>
        </p>
        <br />
        <a className="chartLink" href="img/chart.png" target="_blank" rel="noreferrer noopener">
          Ver Tabla
        </a>
        <img src="img/chart.png" alt="" />
      </div>
    </div>
  );
};

FinancialEntitiesBuroInfo.propTypes = {
  location: ReactRouterPropTypes.location.isRequired
};

export default FinancialEntitiesBuroInfo;

import React from 'react';
import PropTypes from 'prop-types';
import AntForm from '../AntForm';
import { QuotationRequestModalInputs } from '../../../utils/form_inputs/inputsQuotatuionRequest';
import { config } from '../../../config/config';

const {
  quotationModalMessages: { Step2Messages }
} = require('../../../utils/userMessages');

const { Step2 } = QuotationRequestModalInputs;

const QuotationModalStep1 = ({ formRef }) => (
  <div id="Formulario_Datos" >
    <p className="SmallTextModal">{Step2Messages.step}</p>
    <p className="MediumTextModal">{Step2Messages.subtitle}</p>
    <AntForm
      wrappedComponentRef={formRef}
      inputs={Step2}
      noSubmitButton
      RECAPTCHA_SITE_KEY={config.apiKeys.RECAPTCHA_SITE_KEY}
    />
   
  </div>
);

QuotationModalStep1.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })])
    .isRequired
};

export default QuotationModalStep1;
